.title {
  font-size: 2rem;
  font-weight: bold;
  color: transparent;
  background: linear-gradient(
    90deg,
    #970707,
    #d04242 27.21%,
    #d5c112 49.5%,
    #9f4619 74.26%,
    #ac6cbc 74.27%,
    #5b047a 98.5%
  );
  -webkit-background-clip: text;
  margin-left: 20px; /* Add margin to the left of the title */
}

.title-visible {
  display: block;
}

.title-hidden {
  display: none;
}

.navbar {
  height: 85px;
  display: flex;
  
  width: 95%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.bars {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #808080;
}

.nav-menu {
  display: flex;
  align-items: center;
}

.nav-button-container {
  margin-right: 20px;
  display: flex;
  justify-content: flex-end; /* Align nav buttons to the right corner */
}

/* .nav-button {
  margin-left: 10px; 
  margin-right: 10px;
  color:black;
  background-color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  border: 1px solid var(--dark-green-buttons);
  padding: var(--padding-mini) var(--padding-10xl); 
  background-color: var(--white-background); 
  border-radius: var(--br-9xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
} */
.nav-button { 
  margin-left: 10px;
  margin-right: 10px;
  font-size: 22px; /* Increase text size */
  font-weight: bold; /* Make text bold */
  cursor: pointer;
  text-decoration: none;
  /* border: 1px solid var(--dark-green-buttons); */
  padding: var(--padding-mini) var(--padding-10xl);
  border-radius: var(--br-9xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.nav-button:hover {
  background-color: grey; /* Change background color on hover */
  color: #000000;
}

.nav-link {
  font-size: 1.5rem; /* Increase nav link font size */
  color: #808080;
  text-decoration: none;
  padding: 0 20px; /* Adjust padding as needed */
  cursor: pointer;
}

.nav-link.active {
  color: #000000;
}

@media screen and (max-width: 800px) {

  .bars {
    display: block;
  }

  .title-visible {
    display: none; /* Hide the title on smaller screens */
  }

  .title-hidden {
    display: block;
  }

  .nav-menu {
    display: none;
    position: absolute;
    top: 80px;
    right: 20px; /* Adjust position as needed */
    width: 200px; /* Adjust width as needed */
    background-color: linear-gradient(180deg, #0D011C, #181242);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    flex-direction: column;
    align-items: flex-end; /* Align items to the right */
    z-index: 1;
  }

  .nav-menu.active {
    display: flex;
  }

  .nav-button {
    margin: 10px 0; /* Remove horizontal margin on smaller screens */
  }

  .nav-link {
    padding: 10px; /* Adjust padding as needed */
  }
}