.procedure1 {
    position: relative;
    font-size: var(--font-size-xl);
    line-height: 150%;
    font-family: var(--font-abeezee);
    color: var(--color-gray-100);
    text-align: left;
    display: inline-block;
    min-width: 94px;
  }
  .how-to-buy-parent {
    cursor: pointer;
    border: 0;
    padding: var(--padding-5xs) var(--padding-base);
    background-color: var(--unnamed-color1);
    border-radius: var(--br-17xl);
    background: linear-gradient(90deg);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .how-to {
    color: var(--unnamed-color);
  }
  .buy {
    color: var(--color-darkturquoise);
  }
  .span {
    color: var(--unnamed-color);
  }
  .authenticate {
    color: var(--color-darkturquoise);
  }
  .how-to-buy-authenticate,
  .products-here {
    margin: 0;
  }
  .how-to-buy-container {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 130%;
    font-weight: 400;
    font-family: inherit;
  }
  .text1 {
    width: 457px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--padding-xl) 0 0;
    box-sizing: border-box;
    gap: var(--gap-5xl);
    max-width: 100%;
  }
  .subtract-icon {
    align-self: stretch;
    height: 215.4px;
    position: relative;
    border-radius: var(--br-base);
    max-width: 100%;
    overflow: hidden;
    flex-shrink: 0;
  }
  .sell-icon {
    width: 72px;
    height: 72px;
    position: absolute;
    margin: 0 !important;
    top: calc(47% - 98.6px);
    left: 85%;
    object-fit: contain;
    z-index: 1;
  }
  .sell-your-product {
    width: 192px;
    position: relative;
    line-height: 118.5%;
    display: inline-block;
  }
  .you-can-sell {
    align-self: stretch;
    position: relative;
    font-size: var(--font-size-xl);
    line-height: 150%;
    font-family: var(--font-roboto);
    color: var(--color-gray-100);
  }
  .info2 {
    width: 529.6px;
    margin: 0 !important;
    position: absolute;
    top: 55px;
    left: 40;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    max-width: 100%;
    z-index: 1;
  }
  .subtract-icon1,
  .third-div {
    position: relative;
    max-width: 100%;
  }
  .third-div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-3xs);
    min-width: 406px;
  }
  .subtract-icon1 {
    border: 0;
    background-color: var(--unnamed-color1);
    height: 215.4px;
    width: 624px;
    outline: 0;
    border-radius: var(--br-base);
  }
  .coin-wallet-icon {
    height: 72px;
    width: 72px;
    position: relative;
    object-fit: contain;
    z-index: 1;
    margin-left: -80px;
  }
  .set-up-your {
    position: relative;
    line-height: 118.5%;
  }
  .connect-your-wallet {
    align-self: stretch;
    height: 30px;
    position: relative;
    font-size: var(--font-size-xl);
    line-height: 150%;
    font-family: var(--font-open-sans);
    color: var(--color-gray-100);
    display: inline-block;
  }
  .info3 {
    width: 487.3px;
    margin: 0 !important;
    position: absolute;
    top: 50px;
    left: 56px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    max-width: 100%;
    z-index: 1;
  }
  .first-div,
  .subtract-icon2 {
    flex: 1;
    position: relative;
    max-width: 100%;
  }
  .first-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 406px;
  }
  .subtract-icon2 {
    align-self: stretch;
    border-radius: var(--br-base);
    overflow: hidden;
    max-height: 100%;
  }
  .add-new-icon {
    width: 72px;
    height: 72px;
    position: absolute;
    margin: 0 !important;
    top: 8.6px;
    left: 515px;
    object-fit: contain;
    z-index: 1;
  }
  .create-unique-collection {
    width: 280px;
    position: relative;
    line-height: 118.5%;
    display: inline-block;
  }
  .list-the-products {
    align-self: stretch;
    height: 92px;
    position: relative;
    font-size: var(--font-size-xl);
    line-height: 150%;
    font-family: var(--font-open-sans);
    color: var(--color-gray-100);
    display: inline-block;
    flex-shrink: 0;
  }
  .info4 {
    width: 525px;
    margin: 0 !important;
    position: absolute;
    top: 55px;
    left: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    max-width: 100%;
    z-index: 2;
  }
  .second-div,
  .top-div {
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  .second-div {
    height: 221.4px;
    width: 604px;
    flex-direction: column;
    justify-content: flex-end;
    padding: var(--padding-10xs) 0;
    box-sizing: border-box;
    position: relative;
  }
  .top-div {
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--gap-13xl);
  }
  .three-points {
    align-self: stretch;
    flex-direction: row;
    text-align: left;
    font-size: var(--font-size-3xl);
    font-family: var(--font-abeezee);
  }
  .info1,
  .procedure,
  .three-points {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
  }
  .procedure {
    width: 1916px;
    flex-direction: column;
    gap: var(--gap-31xl);
  }
  .info1 {
    align-self: stretch;
    overflow: hidden;
    flex-direction: row;
    padding: 10 var(--padding-xl) 0 var(--padding-104xl);
    box-sizing: border-box;
    text-align: center;
    font-size: var(--font-size-21xl);
    color: var(--unnamed-color);
    font-family: var(--font-abel);
  }
  @media screen and (max-width: 1250px) {
    .info1 {
      padding-left: var(--padding-42xl);
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 825px) {
    .how-to-buy-container {
      font-size: var(--font-size-13xl);
      line-height: 42px;
    }
    .first-div,
    .third-div{
      height: 221.4px;
      width: 604px;
      flex-direction: column;
      justify-content: flex-end;
      padding: var(--padding-10xs) 0;
      box-sizing: border-box;
      position: relative;
    }
    .three-points,
    .top-div {
      gap: var(--gap-base);
    }
    .procedure {
      gap: var(--gap-6xl);
    }
    .info1 {
      padding-left: var(--padding-11xl);
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 450px) {
    .procedure1 {
      font-size: var(--font-size-base);
      line-height: 24px;
    }
    .how-to-buy-container {
      font-size: var(--font-size-5xl);
      line-height: 31px;
    }
    .sell-your-product {
      font-size: var(--button-size);
      line-height: 21px;
    }
    .you-can-sell {
      font-size: var(--font-size-base);
      line-height: 24px;
    }
    .set-up-your {
      font-size: var(--button-size);
      line-height: 21px;
    }
    .connect-your-wallet {
      font-size: var(--font-size-base);
      line-height: 24px;
    }
    .create-unique-collection {
      font-size: var(--button-size);
      line-height: 21px;
    }
    .list-the-products {
      font-size: var(--font-size-base);
      line-height: 24px;
    }
  }
  