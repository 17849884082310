.bnb-chain-binance-smart-chain-icon {
  align-self: stretch;
  height: 92px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.metamask-icon {
  flex: 0.6111;
  border-radius: var(--br-base);
  background-color: rgba(255,255,255,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-47xl) var(--padding-55xl);
  box-sizing: border-box;
  min-width: 258px;
  max-width: 280px;
}
.icon-4 {
  align-self: stretch;
  height: 160px;
  border-radius: var(--br-15xl);
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.solodity-icon {
  flex: 1;
  border-radius: var(--br-base);
  background-color: rgba(255,255,255,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-13xl);
  box-sizing: border-box;
  min-width: 258px;
  max-width: 280px;
}
.icon-41 {
  align-self: stretch;
  height: 160px;
  border-radius: var(--br-15xl);
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.etherium-icon {
  flex: 1;
  border-radius: var(--br-base);
  background-color: rgba(255,255,255,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-13xl);
  box-sizing: border-box;
  min-width: 258px;
  max-width: 280px;
}
.icon-42 {
  align-self: stretch;
  height: 160px;
  border-radius: var(--br-15xl);
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.bitcoin-icon,
.inner-div-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bitcoin-icon {
  flex: 1;
  border-radius: var(--br-base);
  background-color: rgba(255,255,255,0.1);
  flex-direction: column;
  padding: var(--padding-13xl);
  box-sizing: border-box;
  min-width: 258px;
  max-width: 280px;
}
.inner-div-icons {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-6xl) var(--padding-17xl);
  gap: var(--gap-21xl);
}
.icons-div,
.main-div-icons {
  display: flex;
  justify-content: center;
  max-width: 100%;
}
.main-div-icons {
  width: 1374px;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-13xl) var(--padding-13xl) var(--padding-13xl)
    var(--padding-11xl);
  box-sizing: border-box;
}
.icons-div {
  width: 1576px;
  flex-direction: row;
  align-items: center;
  margin-left: -7px;
}
@media screen and (max-width: 1200px) {
  .inner-div-icons {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 750px) {
  .inner-div-icons {
    gap: var(--gap-xl);
  }
}
@media screen and (max-width: 450px) {
  .metamask-icon {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
    flex: 1;
  }
  .bitcoin-icon,
  .etherium-icon,
  .solodity-icon {
    gap: var(--gap-base);
  }
}
