.image-6-icon {
  width: 79px;
  height: 99px;
  position: absolute;
  margin: 0 !important;
  top: 500px;
  left: 545px;
  object-fit: contain;
}
.authenticheck-description-icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.authenticheck-marketplace-over {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  line-height: 38px;
  font-weight: 800;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.heading {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.div,
.world-arts {
  position: relative;
  line-height: 150%;
  display: inline-block;
  min-width: 90px;
  white-space: nowrap;
}
.div {
  font-size: var(--font-size-13xl);
  line-height: 38px;
  font-family: var(--font-abel);
  color: var(--unnamed-color);
  min-width: 114px;
}
.worldarts-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  gap: var(--gap-base);
}
.digital-artists,
.div1 {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}
.digital-artists {
  width: 115px;
  line-height: 150%;
}
.div1 {
  font-size: var(--font-size-13xl);
  line-height: 118.5%;
  font-family: var(--font-abel);
  color: var(--unnamed-color);
  min-width: 109px;
}
.digital-artist-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 20px 20px 20px;
  gap: var(--gap-base);
}
.div2,
.live-auctions {
  position: relative;
  white-space: nowrap;
}
.live-auctions {
  align-self: stretch;
  line-height: 150%;
}
.div2 {
  font-size: var(--font-size-13xl);
  line-height: 118.5%;
  font-family: var(--font-abel);
  color: var(--unnamed-color);
  display: inline-block;
  min-width: 111px;
}
.live-auction-div {
  width: 109.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  gap: var(--gap-base);
}
.div3,
.unique-products {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}
.unique-products {
  width: 139px;
  line-height: 150%;
}
.div3 {
  font-size: var(--font-size-13xl);
  line-height: 38px;
  font-family: var(--font-abel);
  color: var(--unnamed-color);
  min-width: 114px;
}
.unique-products-div {
  width: 109.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  gap: var(--gap-base);
}
.details,
.overview {
  align-self: stretch;
  display: flex;
}
.details {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-gray-100);
  font-family: var(--font-abeezee);
}
.overview {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-4xs);
  box-sizing: border-box;
  gap: var(--gap-13xl);
  max-width: 100%;
}
.descritption,
.image-icon {
  position: relative;
  max-width: 100%;
}
.descritption {
  width: 668px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-15xl-5) var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-76xl);
  min-width: 668px;
}
.image-icon {
  width: 812px;
  max-height: 100%;
  object-fit: cover;
  padding-top: 5%;
}
.main-div-top-section,
.top-section {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.main-div-top-section {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.top-section {
  width: 1930px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-11xs) var(--padding-6xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--unnamed-color);
  font-family: var(--font-abel);
}
@media screen and (max-width: 1550px) {
  .descritption,
  .image-icon {
    flex: 1;
  }
  .main-div-top-section {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 825px) {
  .authenticheck-marketplace-over,
  .div,
  .div1,
  .div2,
  .div3 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .details {
    flex-wrap: wrap;
    justify-content: center;
  }
  .overview {
    gap: var(--gap-base);
  }
  .descritption {
    gap: var(--gap-28xl);
    padding-top: var(--padding-3xl);
    padding-bottom: var(--padding-3xl);
    box-sizing: border-box;
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .authenticheck-marketplace-over {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .world-arts {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
  .div {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .digital-artists {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
  .div1 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .live-auctions {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
  .div2 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .unique-products {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
  .div3 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .descritption {
    gap: var(--gap-5xl);
  }
}
