@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel:wght@400;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;700;800&display=swap");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-poppins: Poppins;
  --font-open-sans: "Open Sans";
  --font-abeezee: ABeeZee;
  --font-roboto: Roboto;
  --font-abel: Abel;
  --button: Inter;

  /* font sizes */
  --font-size-xl: 20px;
  --font-size-base: 16px;
  --font-size-3xl: 22px;
  --button-size: 18px;
  --font-size-21xl: 40px;
  --font-size-13xl: 32px;
  --font-size-5xl: 24px;
  --font-size-7xl: 26px;
  --font-size-lgi: 19px;
  --button-size: 18px;
  --font-size-17xl: 36px;

  /* Colors landing*/
  --unnamed-color3: rgba(255, 255, 255, 0.03);
  --unnamed-color2: rgba(255, 255, 255, 0.05);
  --color-gray-100: rgba(255, 255, 255, 0.8);
  --unnamed-color: #fff;
  --color-darkturquoise: #1ee0e1;
  --color-gainsboro-100: #e6e6e6;
  --color-gainsboro-200: rgba(230, 230, 230, 0.05);
  --dark-green-buttons: #3a4f39;
  /*color Login */
  --color-dimgray: #61755e;
  --color-whitesmoke: #f0f0f0;
  --color-dimgray: #595959;
  --color-darkgray: #adadad;
  --white-background: #fff;
  --color-red: #ff0303;
  /* Color AboutUs */
  --color-purple-100: #7e0488;
  --color-purple-200: #7a0385;
  --color-darkslateblue: #50225c;
  --white-background: #fff;
  --color-gainsboro: #e6e6e6;

  /* Gaps */
  --gap-31xl: 50px;
  --gap-6xl: 25px;
  --gap-base: 16px;
  --gap-13xl: 32px;
  --gap-5xl: 24px;
  --gap-3xs: 10px;
  --gap-81xl: 100px;
  --gap-xl: 20px;
  --gap-mid: 17px;
  --gap-6xs: 7px;
  --gap-21xl: 40px;
  --gap-76xl: 95px;
  --gap-28xl: 47px;
  /*Gaps login*/
  --gap-13xl: 32px;
  --gap-204xl-5: 223.5px;
  --gap-37xl: 56px;
  --gap-93xl: 112px;
  --gap-14xl: 33px;
  --gap-2xs: 11px;
  /* Gaps AboutUs */
  --gap-base: 16px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-104xl: 123px;
  --padding-11xl: 30px;
  --padding-42xl: 61px;
  --padding-10xs: 3px;
  --padding-5xs: 8px;
  --padding-base: 16px;
  --padding-9xl-6: 28.6px;
  --padding-xs: 12px;
  --padding-21xl: 40px;
  --padding-13xl: 32px;
  --padding-2xl: 21px;
  --padding-9xl: 28px;
  --padding-12xs: 1px;
  --padding-11xs: 2px;
  --padding-6xs: 7px;
  --padding-8xs: 5px;
  --padding-6xl: 25px;
  --padding-15xl-5: 34.5px;
  --padding-3xs: 10px;
  --padding-3xl: 22px;
  --padding-4xs: 9px;
  --padding-mini: 15px;
  --padding-66xl: 85px;
  --padding-27xl-5: 46.5px;
  --padding-lg-5: 18.5px;
  --padding-22xl-5: 41.5px;
  --padding-mid: 17px;
  --padding-10xl: 29px;
    /* Paddings Login*/
    --padding-204xl-5: 223.5px;
    --padding-75xl: 94px;
    --padding-126xl: 145px;
    /* Paddings */
  --padding-17xl: 36px;
  --padding-47xl: 66px;
  --padding-55xl: 74px;
  --padding-23xl: 42px;
  --padding-lgi: 19px;


 /* Border radiuses */
 --br-6xs: 7px;
 --br-base: 16px;
 --br-9xs: 4px;
 --br-xl: 20px;
 --br-7xs-6: 5.6px;
  /* Border radiuses */
  --br-17xl: 36px;
  --br-5xs: 8px;
  --br-11xl: 30px;
  --br-8xl: 27px;
 /* Border radiuses */
 --br-base: 16px;
 --br-15xl: 34px;
}