:root {
    --primary: #fff;
    --secondary: #98b5d5;
}


.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 2px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--long {
    width: 90%;
    top: 4.7em;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #fff;
    border: 2px solid #98b5d5;
    outline: none;
    padding: 10px;
    font-size: 13px;
    color: rgba(0, 0, 0);
    border-radius: 40px;
    transition: all 0.3s ease-out;
    display: block important;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
    background-color: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
}

.btn--long:hover{
    background-color: #98b5d5;
    color: #fff;
    transition: all 0.3s ease-out;
}