.products {
  color: var(--unnamed-color);
}
.top-selling-products-container {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 130%;
  font-weight: 400;
  font-family: inherit;
}
.first-product-div,
.second-div-product,
.text {
  align-items: center;
  max-width: 100%;
  flex-shrink: 0;
}
.text {
  width: 349px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.first-product-div,
.second-div-product {
  flex-direction: row;
  justify-content: center;
}
.first-product-div {
  display: flex;
  padding: 0 var(--padding-6xs);
  box-sizing: border-box;
  gap: var(--gap-21xl);
}
.second-div-product {
  align-self: stretch;
  display: grid;
  gap: var(--gap-31xl);
  grid-template-columns: repeat(3, minmax(345px, 1fr));
}
.explore-all-products1,
.products1 {
  font-family: var(--font-abeezee);
  color: var(--unnamed-color);
}
.products1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-13xl) var(--padding-13xl) var(--padding-13xl)
    var(--padding-11xl);
  box-sizing: border-box;
  gap: var(--gap-mid);
  max-width: 100%;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-3xl);
}
.explore-all-products1 {
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 150%;
  text-align: center;
}
.explore-all-products {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-21xl);
  background-color: var(--unnamed-color2);
  box-shadow: 0 16px 80px rgba(208, 17, 204, 0.15);
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.explore-all-products:hover {
  background-color: var(--color-gainsboro-200);
}
.product-display-section,
.top-selling-products {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}
.top-selling-products {
  width: 1532.7px;
  justify-content: center;
  gap: var(--gap-xl);
}
.product-display-section {
  width: 1920px;
  overflow: hidden;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) var(--padding-9xl-6);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--color-darkturquoise);
  font-family: var(--font-abel);
}
@media screen and (max-width: 1550px) {
  .first-product-div {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1250px) {
  .second-div-product {
    justify-content: center;
    grid-template-columns: repeat(2, minmax(345px, 598px));
  }
  .products1 {
    padding-top: var(--padding-2xl);
    padding-bottom: var(--padding-2xl);
    box-sizing: border-box;
  }
  .product-display-section {
    gap: var(--gap-81xl);
  }
}
@media screen and (max-width: 825px) {
  .top-selling-products-container {
    font-size: var(--font-size-13xl);
    line-height: 42px;
  }
  .first-product-div {
    gap: var(--gap-xl);
  }
  .second-div-product {
    gap: var(--gap-6xl);
    grid-template-columns: minmax(345px, 1fr);
  }
  .products1 {
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .product-display-section {
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 450px) {
  .top-selling-products-container {
    font-size: var(--font-size-5xl);
    line-height: 31px;
  }
  .text {
    gap: var(--gap-base);
  }
  .product-display-section {
    gap: var(--gap-6xl);
  }
}
