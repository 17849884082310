.about-us {
    margin: 0;
    position: relative;
    font-size: inherit;
    font-weight: 800;
    font-family: inherit;
    background: linear-gradient(270deg);
    -webkit-text-fill-color: transparent;
    display: inline-block;
    max-width: 100%;
  }
  .authenticheck-utilizes-the,
  .we-envision-a {
    margin: 0;
  }
  .authenticheck-utilizes-the-container {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: 28px;
    font-weight: 500;
    font-family: inherit;
  }
  .text {
    flex: 1;
    flex-direction: column;
    padding: 143.5px 0;
    box-sizing: border-box;
    gap: 41px;
    max-width: 100%;
  }
  .aboutinfo-div,
  .aboutus-page,
  .aboutus-text,
  .text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .aboutus-text {
    width: 1560px;
    flex-direction: row;
    padding: 54px 60px;
    box-sizing: border-box;
    max-width: 100%;
  }
  .aboutinfo-div,
  .aboutus-page {
    overflow: hidden;
  }
  .aboutinfo-div {
    width: 1858px;
    flex-shrink: 0;
    flex-direction: column;
    max-width: 100%;
    margin-left: -7px;
    text-align: center;
    font-size: 80px;
    color: darkgray;
    font-family: var(--button);
  }
  .aboutus-page {
    width: 100%;
    position: relative;
    background: linear-gradient(180deg, #020a36, #0d011c 0.01%, #181242 98%);
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 1233px;
    line-height: normal;
    letter-spacing: normal;
  }
  @media screen and (max-width: 1200px) {
    .aboutus-text {
      padding-left: var(--padding-11xl);
      padding-right: var(--padding-11xl);
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 1050px) {
    .about-us {
      font-size: var(--font-size-21xl);
    }
    .aboutus-text {
      padding-top: var(--padding-8xl);
      padding-bottom: 35px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 750px) {
    .text {
      gap: var(--gap-xl);
      padding-top: var(--padding-8xl);
      padding-bottom: 93px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 450px) {
    .about-us {
      font-size: 24px;
    }
    .authenticheck-utilizes-the-container {
      font-size: 22px;
    }
    .aboutus-text {
      padding-bottom: 23px;
      box-sizing: border-box;
    }
  }
  