.landing,
.main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.main-div {
  align-self: stretch;
  padding: 17.3px 0;
  max-width: 100%;
  flex-shrink: 0;
}
.landing {
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, #0D011C, #181242);
  padding: 0;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 1250px) {
  .landing {
    gap: 55px;
  }
}
@media screen and (max-width: 825px) {
  .landing {
    gap: 28px;
  }
}
