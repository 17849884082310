.admin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(180deg, #181242, #0D011C);
}

.admin-content {
    position: relative;
    width: 100%;
    max-width: 600px;
    padding: 50px;
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.admin-text h1 {
    font-size: 3rem;
    margin: 20px 0;
    color: #333;
}

.admin-text h2 {
    font-size: 1.5rem;
    color: #555;
}

.admin-buttons {
    margin-top: 30px;
}

.admin-btn {
    margin-bottom: 15px;
}

/* Add animations */
.admin-content {
    animation: fadeInUp 0.8s ease-in-out;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
