.image-8-icon,
.wrapper-image-8 {
  align-self: center; /* Center the items vertically */
  flex-shrink: 0;
  height: 340px; /* Set the same height */
  max-width: 100%; /* Ensure the image does not exceed its container */
  display: flex; /* Ensure proper flex behavior */
  align-items: center; /* Center the content horizontally */
  justify-content: center; /* Center the content vertically */
}

.image-8-icon {
  /* height: 70%; */
  overflow: hidden;
  object-fit: contain;
  align-items: center;
  /* position: absolute; */
  top: 20px;
  width: 55%;
  transform: scale(1.59);
}
/* .wrapper-image-8 {
  height: 339.2px;
  position: relative;
  border-radius: var(--br-5xs);
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; */
/* } */
.wrapper-image-8 {
    position: relative;
    border-radius: var(--br-5xs);
    /* justify-content: center; */
    /* max-width: 100%; */
    /* display: flex; */
  }
  
.cyber-samurai-575 {
  position: relative;
  line-height: 118.5%;
}
.ethereum-icon {
  height: 32px;
  width: 32px;
  position: relative;
  object-fit: cover;
}
.div4 {
  position: relative;
  line-height: 118.5%;
  display: inline-block;
  min-width: 33px;
}
.price-product1 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.info,
.name-product1 {
  align-self: stretch;
  display: flex;
}
.name-product1 {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.info {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs);
}
.buy-product {
  flex: 1;
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 150%;
  font-family: var(--font-abeezee);
  color: var(--unnamed-color);
  text-align: center;
  display: inline-block;
  max-width: 100%;
}
.buy-product1,
.first-div-product {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
}
.buy-product1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-21xl);
  background-color: var(--unnamed-color1);
  align-self: stretch;
  box-shadow: 0 9px 100px rgba(208, 17, 204, 0.35);
  border-radius: var(--br-5xs);
  background: linear-gradient(103.84deg);
  flex-direction: row;
  white-space: nowrap;
}
.first-div-product {
  width: 466.7px;
  border-radius: var(--br-base);
  background-color: var(--unnamed-color3);
  flex-direction: column;
  padding: var(--padding-13xl);
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--unnamed-color);
  font-family: var(--font-abeezee);
}
@media screen and (max-width: 825px) {
  .name-product1 {
    flex-wrap: wrap;
  }
  .first-div-product {
    gap: var(--gap-base);
    padding-top: var(--padding-2xl);
    padding-bottom: var(--padding-2xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .cyber-samurai-575,
  .div4 {
    font-size: var(--button-size);
    line-height: 21px;
  }
}
